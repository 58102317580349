import React from "react"
import styles from "./Tab.module.scss"
import LinkTo from "../LinkTo"
import ArrowDownThin from "../../../svgs/ArrowDownThin"

const Tab = ({
    text,
    url,
    external,
    target,
    fake,
    className,
    buttonClassName,
}) => {
    return text && url ? (
        <div className={`${className ?? ""} ${styles.buttonWrapper}`}>
            <LinkTo
                className={`
                    ${buttonClassName ?? ""}
                    ${styles.button}
                `}
                activeClassName={styles.buttonActive}
                url={url}
                external={external}
                target={target}
                fake={fake}
            >
                <span>{text}</span>
                <ArrowDownThin />
            </LinkTo>
        </div>
    ) : null
}

export default Tab
