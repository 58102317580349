import React from "react"
import styles from "./InlineCtaAlternate.module.scss"
import Button from "../elements/Button"
import Container from "../layout/Container"
import Row from "../layout/Row"
import Section from "../layout/Section"
import getColors from "../../util/getColors"
import BackgroundPattern from "../elements/BackgroundPattern"
import BackgroundSolid from "../elements/BackgroundSolid"

const InlineCtaAlternate = ({
    title,
    content,
    link,
    secondaryLink,
    reducedSpacingBottom,
    noSpacing,
    theme,
}) => {
    const themeDarkColors = getColors("dark")

    return title || content || link?.url || secondaryLink?.url ? (
        <Section
            className={styles.inlineCtaAlternate}
            reducedSpacingBottom={reducedSpacingBottom}
            noSpacing={noSpacing}
            theme={theme}
        >
            {theme === "brightscan-dark" || theme === "torus-dark" ? (
                <>
                    <BackgroundPattern
                        halfHeight={true}
                        top={true}
                        theme={theme}
                    />
                    <BackgroundSolid
                        halfHeight={true}
                        theme="light"
                        top={false}
                    />
                </>
            ) : null}

            <Container style={themeDarkColors}>
                <div className={styles.inlineCtaAlternate__containerBg}>
                    <Row>
                        <div className={styles.inlineCtaAlternate__wrapper}>
                            <Row
                                className={
                                    styles.inlineCtaAlternate__wrapper__cta
                                }
                            >
                                {title && <h4>{title}</h4>}
                                {content && (
                                    <div
                                        className={
                                            styles.inlineCtaAlternate__wrapper__cta__content
                                        }
                                    >
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: content,
                                            }}
                                        />
                                    </div>
                                )}
                                {link && link.url && (
                                    <Button
                                        url={link.url}
                                        text={link.text}
                                        external={link.target === "_blank"}
                                        filledStyle={true}
                                        fillStyleReverse={true}
                                        altColors={true}
                                    />
                                )}
                                {secondaryLink && secondaryLink.url && (
                                    <Button
                                        url={secondaryLink.url}
                                        text={secondaryLink.text}
                                        external={
                                            secondaryLink.target === "_blank"
                                        }
                                        altColors={true}
                                        altColorsHover={true}
                                    />
                                )}
                            </Row>
                        </div>
                    </Row>
                </div>
            </Container>
        </Section>
    ) : null
}

export default InlineCtaAlternate
