import React, { useState, useEffect, useCallback, useRef } from "react"
import styles from "./PricingPlansTile.module.scss"
import Button from "../../elements/Button"
import Tick from "../../../svgs/Tick"
import FadeUp from "../../effects/FadeUp"
import Chevron from "../../../svgs/Chevron"

const PricingPlansTile = ({ item, index, useMonthlyPrice }) => {
    const [open, setOpen] = useState(false)
    const [contentHeight, setContentHeight] = useState(0)
    const [closedHeight, setClosedHeight] = useState(0)

    const contentContainer = useRef()
    const closedHeightCurrent = useRef()

    const numListItemsIntiallyShown = 3

    const updateScrollHeight = useCallback(() => {
        const contentScrollHeight =
            contentContainer.current?.children[0]?.scrollHeight
        setContentHeight(contentScrollHeight)
    }, [])

    const updateClosedHeight = useCallback(() => {
        const listItems = contentContainer.current?.children[0]?.children ?? []
        let initialHeight = 0

        for (let i = 0; i < numListItemsIntiallyShown; i++) {
            initialHeight += listItems[i]?.scrollHeight
        }

        closedHeightCurrent.current = initialHeight
        setClosedHeight(closedHeightCurrent.current)
    }, [])

    const setHeightAuto = useCallback(() => {
        if (contentContainer.current)
            contentContainer.current.style.height = "auto"
    }, [])

    const unsetHeightAuto = useCallback(() => {
        if (contentContainer.current)
            contentContainer.current.style.height = `${closedHeightCurrent.current}px`
    }, [])

    const updateHeights = useCallback(() => {
        updateScrollHeight()
        updateClosedHeight()
    }, [updateScrollHeight, updateClosedHeight])

    const handleResize = useCallback(() => {
        setHeightAuto()
        updateHeights()
        unsetHeightAuto()
        setOpen(false)
    }, [setHeightAuto, unsetHeightAuto, updateHeights])

    useEffect(() => {
        updateHeights()
        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [updateHeights, handleResize])

    const handleClick = e => {
        e.target.blur()
        updateHeights()
        setOpen(!open)
    }

    const link = item.planCallToAction

    const features =
        item.planFeatures && item.planFeatures.length
            ? item.planFeatures.map((feature, j) => {
                  return (
                      <li key={j}>
                          <Tick /> {feature.featureName}
                      </li>
                  )
              })
            : null

    const inner = (
        <>
            <div className={styles.pricingPlanTile__inner__top}>
                <h4 className={styles.title}>{item.title}</h4>
                <div className={styles.pricingPlanTile__inner__top__content}>
                    <p className={styles.price}>
                        {useMonthlyPrice ? (
                            <strong>{item.monthlyPrice}</strong>
                        ) : (
                            <strong>{item.annualPrice}</strong>
                        )}
                    </p>
                    {item.planDescription ? (
                        <div
                            className={`${styles.content} ${
                                link?.url ? styles.contentHasMargin : ""
                            }`}
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: item.planDescription,
                                }}
                            />
                        </div>
                    ) : null}
                </div>
                {link?.url ? (
                    <Button
                        text={link?.text ?? "Get started"}
                        url={link?.url}
                        external={link?.target === "_blank"}
                        target={link?.target}
                        filledStyle={item.planCallToAction}
                        className={styles.button}
                        buttonClassName={styles.button}
                        // fake={true}
                    />
                ) : null}
            </div>
            <div className={styles.pricingPlanTile__inner__bottom}>
                <div
                    className={`${styles.features} ${
                        open ? styles.featuresOpen : ""
                    }`}
                >
                    <p className={`${styles.features__title} bold`}>
                        This plan includes
                    </p>
                    {features ? (
                        <>
                            <div
                                ref={contentContainer}
                                className={styles.features__listWrapper}
                                style={{
                                    height:
                                        open || contentHeight < closedHeight
                                            ? `${contentHeight}px`
                                            : `${closedHeight}px`,
                                }}
                            >
                                <ul>{features}</ul>
                            </div>
                            {contentHeight > closedHeight &&
                                item.planFeatures?.length >
                                    numListItemsIntiallyShown && (
                                    <button
                                        aria-expanded={open}
                                        onClick={handleClick}
                                    >
                                        View all features
                                        <span className={styles.linkSvg}>
                                            <Chevron />
                                        </span>
                                    </button>
                                )}
                        </>
                    ) : null}
                </div>
                {item.planAdditionalInformation ? (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: item.planAdditionalInformation,
                        }}
                        className={styles.afterContent}
                    />
                ) : null}
            </div>
        </>
    )

    return (
        <FadeUp
            delay={`${(index % 3) * 0.15}s`}
            threshold={0}
            disableDelayMobile={true}
            className={styles.pricingPlanTile}
        >
            <div className={styles.pricingPlanTile__inner}>{inner}</div>
        </FadeUp>
    )
}

export default PricingPlansTile
