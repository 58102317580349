import React, { useState } from "react"
import SEO from "../components/seo"
import HeroPricing from "../components/HeroPricing"
import { graphql } from "gatsby"
import Introduction from "../components/Introduction"
import Cta from "../components/Cta"
import Solutions from "../components/Solutions"
import Accordion from "../components/Accordion"
import PricingPlans from "../components/PricingPlans/PricingPlans"
import Footer from "../components/Footer"
import InlineCtaAlternate from "../components/InlineCtaAlternate"
import Table from "../components/Table"

function createTabs(pricingPages, categories) {
    let indexedCategories = {}
    categories.forEach(category => {
        indexedCategories[category.slug] = category
        indexedCategories[category.slug].tabs = []
    })
    pricingPages.forEach(page => {
        indexedCategories[page.planCategory[0].slug].tabs.push(page)
    })
    return indexedCategories
}

const PricingPage = props => {
    const craftData = props.data.craft.entry

    const [headerActive, setHeaderActive] = useState(false)
    const [headerHeight, setHeaderHeight] = useState(0)

    let indexedTabs = createTabs(
        props.data.craft.pricingPages,
        props.data.craft.categories
    )
    const {
        title,
        pricingLogo,
        pricingHero,
        pricingPlans,
        pricingCallToAction,
        pricingFeatureComparisonTable,
        solutions,
        accordion,
        callToAction,
        planCategory,
        hubspotForm
    } = craftData

    const activeTab = indexedTabs[planCategory[0].slug]
    const secondaryTabs = activeTab?.tabs

    const primaryTabs = Object.values(indexedTabs).map(data => {
        const firstTab = data.tabs.length ? data.tabs[0] : null
        return {
            ...data,
            uri: firstTab ? `/${firstTab.uri}/` : "/",
        }
    })

    const heroData = pricingHero ? pricingHero[0] : {}
    const { displayTitle } = heroData
    const plansIntroLogo = pricingLogo?.length ? pricingLogo[0]?.url : null

    const plansItems = pricingPlans?.length ? pricingPlans : null

    const tableData = pricingFeatureComparisonTable
    const afterTableContent = pricingPlans?.featureTableAdditionalInformation

    const {
        ctaTitle: inlineCtaTitle,
        ctaContent: inlineCtaContent,
        primaryCta: inlineCtaLink1,
        secondaryCta: inlineCtaLink2,
    } = pricingCallToAction?.length ? pricingCallToAction[0] : {}

    const solutionsData = solutions && solutions.length ? solutions[0] : {}
    const { solutionsTitle, solutions: solutionsItems } = solutionsData
    const solutionsContent =
        solutionsData &&
        solutionsData.solutionsContent &&
        `<p>${solutionsData.solutionsContent}</p>`

    const accordionData = accordion ? accordion[0] : {}
    const { accordionTitle, afterContent } = accordionData
    const accordionListItems =
        accordionData && accordionData.items.length
            ? accordionData.items.map(item => {
                  return {
                      title: item.itemTitle,
                      content: item.itemContent,
                  }
              })
            : null

    const ctaData = callToAction && callToAction.length ? callToAction[0] : {}
    const {
        ctaTitle: ctaText,
        primaryLink: ctaLink1,
        secondaryLink: ctaLink2,
    } = ctaData

    const updateHeaderActiveState = ({ activeState, heightState }) => {
        setHeaderActive(activeState)
        setHeaderHeight(heightState)
    }

    return (
        <>
            <SEO title={title || ""} seomatic={craftData.seomatic} />
            <HeroPricing
                title={"Pricing"}
                tabs={primaryTabs}
                activeTab={activeTab}
                theme="light"
                updatePageState={updateHeaderActiveState}
            />
            <PricingPlans
                title={displayTitle || null}
                logo={plansIntroLogo}
                tabs={secondaryTabs}
                items={plansItems}
                theme="brightscan-dark"
            />
            <InlineCtaAlternate
                title={inlineCtaTitle}
                content={inlineCtaContent}
                link={inlineCtaLink1}
                secondaryLink={inlineCtaLink2}
                noSpacing={true}
                theme="brightscan-dark"
            />
            <Table
                title="Compare our plans"
                plansData={plansItems}
                tableData={tableData}
                afterContent={afterTableContent || null}
                hubspotForm={hubspotForm[0] ?? null}
                noSpacing={true}
                theme="light"
                pageHeaderActive={headerActive}
                pageHeaderHeight={headerHeight}
            />
            <Introduction
                title={solutionsTitle}
                content={solutionsContent}
                colorsAlt={true}
                shortPaddingBottom={solutionsData}
                theme="light"
            />
            <Solutions items={solutionsItems} theme="light" />
            <Accordion
                title={accordionTitle || null}
                afterContent={afterContent || null}
                items={accordionListItems}
                theme="light"
            />
            <Cta
                text={ctaText}
                link1={ctaLink1}
                link2={ctaLink2}
                theme="brightscan-dark"
            />
            <Footer theme="dark" />
        </>
    )
}

export const pageQuery = graphql`
    query pricing($id: CRAFT_QueryArgument!) {
        craft {
            entry(id: [$id]) {
                id
                slug
                uri
                title
                seomatic(asArray: true) {
                    metaTitleContainer
                    metaTagContainer
                    metaLinkContainer
                    metaScriptContainer
                    metaJsonLdContainer
                }
                ... on CRAFT_pricing_pricing_Entry {
                    pricingOrder
                    featureTableAdditionalInformation
                    pricingLogo {
                        url
                    }
                    planCategory {
                        ... on CRAFT_pricingCategories_pricingCategories_Entry {
                            slug
                            title
                        }
                    }
                    pricingHero {
                        ... on CRAFT_pricingHero_BlockType {
                            displayTitle
                        }
                    }
                    pricingPlans {
                        ... on CRAFT_plans_plans_Entry {
                            id
                            title
                            monthlyPrice
                            annualPrice
                            planDescription
                            planCallToAction {
                                url
                                text
                                target
                            }
                            planFeatures {
                                ... on CRAFT_planFeatures_BlockType {
                                    featureName
                                }
                            }
                            planAdditionalInformation
                        }
                    }
                    pricingCallToAction {
                        ... on CRAFT_pricingCallToAction_BlockType {
                            ctaTitle
                            ctaContent
                            primaryCta {
                                url
                                text
                                target
                            }
                            secondaryCta {
                                url
                                text
                                target
                            }
                        }
                    }
                    pricingFeatureComparisonTable {
                        ... on CRAFT_pricingFeatureComparisonTable_featureGroup_BlockType {
                            featureGroupTitle
                            featureGroupFeatures {
                                ... on CRAFT_featureGroupFeatures_BlockType {
                                    featureTitle
                                    featurePlans {
                                        ... on CRAFT_plans_plans_Entry {
                                            id
                                            title
                                        }
                                    }
                                }
                            }
                        }
                    }
                    hubspotForm {
                        ...on CRAFT_hubspotForm_BlockType {
                            portalId
                            formId
                        }
                    }
                    solutions {
                        ... on CRAFT_solutions_BlockType {
                            solutionsTitle
                            solutionsContent
                            solutions {
                                ... on CRAFT_solutions_solution_BlockType {
                                    solutionTitle
                                    solutionContent
                                    solutionLink {
                                        text
                                        url
                                        target
                                    }
                                }
                            }
                        }
                    }
                    accordion {
                        ... on CRAFT_accordion_BlockType {
                            accordionTitle
                            afterContent
                            items {
                                ... on CRAFT_items_item_BlockType {
                                    itemTitle
                                    itemContent
                                }
                            }
                        }
                    }
                    callToAction {
                        ... on CRAFT_callToAction_BlockType {
                            ctaTitle
                            primaryLink {
                                type
                                url
                                target
                                text
                            }
                            secondaryLink {
                                type
                                url
                                target
                                text
                            }
                        }
                    }
                }
            }
            pricingPages: entries(section: "pricing", orderBy: "pricingOrder") {
                id
                uri
                title
                ... on CRAFT_pricing_pricing_Entry {
                    pricingHero {
                        ... on CRAFT_pricingHero_BlockType {
                            displayTitle
                        }
                    }
                    pricingOrder
                    planCategory {
                        id
                        ... on CRAFT_pricingCategories_pricingCategories_Entry {
                            slug
                            title
                        }
                    }
                }
            }
            categories: entries(
                section: "pricingCategories"
                orderBy: "categoryOrder"
            ) {
                id
                ... on CRAFT_pricingCategories_pricingCategories_Entry {
                    slug
                    title
                }
            }
        }
    }
`

export default PricingPage
