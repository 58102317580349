import React from "react";
import styles from "./Solutions.module.scss";
import Button from "../elements/Button";
import Slider from "../Slider";
import LinkTo from "../elements/LinkTo";
import FadeUp from "../effects/FadeUp";
import Section from "../layout/Section";
import Container from "../layout/Container";
import Row from "../layout/Row";

const Solutions = ({items, theme}) => {
    const itemsElements = items && items.length ? items.map((item,i) => {
        const inner = (
            <>
                {item.tag ? <p className={`tag ${styles.tag}`}>{item.tag}</p> : null}
                {item.solutionTitle ? <h4 className="h4-alt">{item.solutionTitle}</h4> : null}
                {item.solutionContent ?
                    <div className={`${styles.content} ${item.solutionLink.url ? styles.contentHasMargin : ""}`}>
                        <span dangerouslySetInnerHTML={{__html: item.solutionContent}} />
                    </div>
                : null}
                {item.solutionLink && item.solutionLink.url ? <Button text="Learn more" url={item.solutionLink.url} external={item.solutionLink.target === "_blank"} target={item.solutionLink.target} simpleStyle={true} fake={true} /> : null}
            </>
        );

        return (
            <FadeUp delay={`${i * 0.15}s`} disableDelayMobile={true} className={styles.solutions__itemsContainer__item} key={i}>
                {item.solutionLink && item.solutionLink.url ? (
                    <LinkTo url={item.solutionLink.url} external={item.solutionLink.target === "_blank"} target={item.solutionLink.target} className={`${styles.solutions__itemsContainer__item__inner} ${styles.solutions__itemsContainer__item__innerButton}`}>{inner}</LinkTo>
                ) : (
                    <div className={styles.solutions__itemsContainer__item__inner}>{inner}</div>
                )}
            </FadeUp>
        );
    }) : null;

    return itemsElements && (
        <Section className={`${styles.solutions} ${itemsElements && itemsElements.length === 1 ? styles.solutionsHasOnlyOne : ""}`} theme={theme}>
            <Container className={styles.rowView}>
                <Row>
                    <div className={styles.solutions__content}>
                        <div className={styles.solutions__itemsContainer}>
                            {itemsElements}
                        </div>
                    </div>
                </Row>
            </Container>
            <div className={styles.sliderView}>
                {itemsElements ? <Slider items={items} itemElements={itemsElements} type="solutions" /> : null }
            </div>
        </Section>
    );
};

export default Solutions;