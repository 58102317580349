import React, { useLayoutEffect, useState } from "react"
import styles from "./PricingPlans.module.scss"
import Toggle from "../elements/Toggle"
import BackgroundPattern from "../elements/BackgroundPattern"
import FadeUp from "../effects/FadeUp"
import Section from "../layout/Section"
import Container from "../layout/Container"
import Row from "../layout/Row"
import PricingPlansTile from "./PricingPlansTile"
import Tab from "../elements/Tab"
import getColors from "../../util/getColors"

const PricingPlans = ({ title, logo, tabs, items, theme }) => {
    const [priceToggleMonthly, setPriceToggleMonthly] = useState(true)
    const themeLightColors = getColors("light")

    const handleToggleClick = () => {
        sessionStorage.setItem("priceToggleMonthly", !priceToggleMonthly)
        setPriceToggleMonthly(!priceToggleMonthly)
    }

    useLayoutEffect(() => {
        if (typeof window.sessionStorage !== undefined) {
            const savedPriceToggleMonthly = sessionStorage
                .getItem("priceToggleMonthly")
                ?.toString()
            const initialPriceToggleMonthly =
                savedPriceToggleMonthly === "false" ? false : true
            setPriceToggleMonthly(initialPriceToggleMonthly)
        }
    }, [])

    const itemsElements =
        items && items.length
            ? items.map((item, i) => (
                  <PricingPlansTile
                      item={item}
                      useMonthlyPrice={priceToggleMonthly}
                      index={i}
                      key={i}
                  />
              ))
            : null

    return title || itemsElements ? (
        <Section className={styles.pricingPlan} noSpacing={true} theme={theme}>
            {theme === "brightscan-dark" || theme === "torus-dark" ? (
                <BackgroundPattern theme={theme} />
            ) : null}

            <Container style={themeLightColors}>
                {tabs ? (
                    <div className={styles.pricingPlan__containerTabs}>
                        {tabs.map((tab, i) => {
                            return (
                                <Tab
                                    url={tab.url ?? tab.uri}
                                    text={tab.pricingHero[0]?.displayTitle}
                                    key={i}
                                />
                            )
                        })}
                    </div>
                ) : null}
                <div className={styles.pricingPlan__containerBg}>
                    {logo || title ? (
                        <Row>
                            <FadeUp className={styles.pricingPlan__intro}>
                                {logo ? (
                                    <img
                                        src={logo}
                                        className={
                                            styles.pricingPlan__intro__logo
                                        }
                                        alt={title}
                                    />
                                ) : null}
                                {title ? (
                                    <h5
                                        className={`${
                                            styles.pricingPlan__intro__title
                                        } ${
                                            logo
                                                ? styles.pricingPlan__intro__titleHasLogo
                                                : ""
                                        }`}
                                    >
                                        {title}
                                    </h5>
                                ) : null}
                                <Toggle
                                    mobileText="Pay Annually / Monthly"
                                    leftText="Pay Annually"
                                    rightText="Pay Monthly"
                                    isLeft={!priceToggleMonthly}
                                    onClick={handleToggleClick}
                                />
                            </FadeUp>
                        </Row>
                    ) : null}
                    {itemsElements && (
                        <Row className={styles.pricingPlan__itemsRow}>
                            <div className={styles.pricingPlan__itemsContainer}>
                                {itemsElements}
                            </div>
                        </Row>
                    )}
                </div>
            </Container>
        </Section>
    ) : null
}

export default PricingPlans
