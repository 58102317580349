import React, { useCallback, useEffect, useRef, useState } from "react"
import styles from "./Table.module.scss"
import Container from "../layout/Container"
import Row from "../layout/Row"
import Section from "../layout/Section"
import Tick from "../../svgs/Tick"
import LinkTo from "../elements/LinkTo"
import FadeUp from "../effects/FadeUp"
import Form from "react-hubspot-form"

const Table = ({
    title,
    plansData,
    tableData,
    afterContent,
    reducedSpacingBottom,
    noSpacing,
    theme,
    pageHeaderActive,
    pageHeaderHeight,
    hubspotForm,
}) => {
    const [headerHeight, setHeaderHeight] = useState(0)
    const scrollLeft = useRef(0)
    const tableWrapper = useRef()
    const scrollableEls = useRef()
    const stickyHorizontalEls = useRef()
    const header = useRef()

    const calcHeaderHeight = useCallback(() => {
        const height = header.current?.scrollHeight
        setHeaderHeight(height)
    }, [])

    const resetHorizontalScroll = useCallback(() => {
        if (scrollableEls.current) {
            scrollableEls.current.forEach(el => {
                el.scrollLeft = 0
            })
        }
    }, [])

    const handleResize = useCallback(() => {
        calcHeaderHeight()
        resetHorizontalScroll()
    }, [calcHeaderHeight])

    useEffect(() => {
        scrollableEls.current = tableWrapper.current?.querySelectorAll(
            ".scrollable"
        )
        stickyHorizontalEls.current = tableWrapper.current?.querySelectorAll(
            ".sticky-horizontal"
        )
        calcHeaderHeight()

        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    const handleHoriztonalScroll = e => {
        const newScrollLeft = e.target.scrollLeft
        scrollLeft.current = newScrollLeft

        if (scrollableEls.current) {
            scrollableEls.current.forEach(el => {
                el.scrollLeft = scrollLeft.current
            })
        }

        if (stickyHorizontalEls.current) {
            stickyHorizontalEls.current.forEach(el => {
                el.style.transform = `translateX(${scrollLeft.current}px)`
            })
        }
    }

    const orderOfColumns = plansData?.length
        ? plansData.map(plan => plan.id)
        : []

    const linksRows = (
        <tr>
            <th className={`sticky-horizontal ${styles.heading}`}></th>
            {plansData?.length
                ? plansData.map(({ planCallToAction, title }, lI) => (
                      <td className={styles.link} key={lI}>
                          {planCallToAction ? (
                              <LinkTo
                                  url={planCallToAction.url}
                                  target={planCallToAction.target}
                                  external={
                                      planCallToAction.target === "_target"
                                  }
                              >
                                  Get started with <strong>{title}</strong>
                              </LinkTo>
                          ) : null}
                      </td>
                  ))
                : null}
        </tr>
    )

    const tableHeadings = (
        <table>
            {plansData?.length ? (
                <thead>
                    <tr>
                        <th
                            className={`
                                sticky-horizontal
                                ${styles.table__header__placeholderTh}
                            `}
                        ></th>
                        {plansData.map((plan, i) => (
                            <th className={styles.table__header__th} key={i}>
                                {plan.title}
                            </th>
                        ))}
                    </tr>
                </thead>
            ) : null}
        </table>
    )

    const tableCategories = tableData.map((category, cI) => {
        const categoryTitle = (
            <div
                className={`
                    ${styles.table__category__title__outer}
                `}
                style={{
                    "--plan-cols": orderOfColumns?.length,
                }}
            >
                <div className={styles.table__category__title__inner}>
                    <div
                        className={`
                            sticky-horizontal
                            ${styles.table__category__title__title}
                        `}
                    >
                        {category.featureGroupTitle}
                    </div>
                </div>
            </div>
        )

        const tableCategoryBody = (
            <table>
                <tbody>
                    {category.featureGroupFeatures?.length
                        ? category.featureGroupFeatures.map((row, rI) => (
                              <tr key={rI}>
                                  <th
                                      className={`sticky-horizontal ${styles.heading}`}
                                  >
                                      {row.featureTitle}
                                  </th>
                                  {orderOfColumns?.length
                                      ? orderOfColumns.map((id, iI) => {
                                            const rowColumnData =
                                                row.featurePlans
                                            const columnDataOfId = rowColumnData.find(
                                                data => data.id === id
                                            )

                                            return (
                                                <td
                                                    className={styles.result}
                                                    key={iI}
                                                >
                                                    {columnDataOfId ? (
                                                        <Tick />
                                                    ) : null}
                                                </td>
                                            )
                                        })
                                      : null}
                              </tr>
                          ))
                        : null}
                    {cI === tableData.length - 1 && linksRows}
                </tbody>
            </table>
        )

        return (
            <FadeUp key={cI}>
                <div className={styles.table__category}>
                    <div
                        className={`scrollable ${styles.table__scrollable} ${styles.table__sticky} ${styles.table__category__title}`}
                        style={{
                            top: pageHeaderActive
                                ? `${headerHeight + pageHeaderHeight}px`
                                : `${headerHeight}px`,
                        }}
                        onScroll={handleHoriztonalScroll}
                    >
                        {categoryTitle}
                    </div>

                    <div
                        className={`scrollable ${styles.table__scrollable}`}
                        onScroll={handleHoriztonalScroll}
                    >
                        {tableCategoryBody}
                    </div>
                </div>
            </FadeUp>
        )
    })

    const table = (
        <>
            <div
                className={`${styles.table__sticky} ${styles.table__header}`}
                style={{
                    top: pageHeaderActive ? `${pageHeaderHeight}px` : "0px",
                }}
                ref={header}
            >
                <div
                    className={`scrollable ${styles.table__scrollable}`}
                    onScroll={handleHoriztonalScroll}
                >
                    {tableHeadings}
                </div>
            </div>

            {tableCategories}
        </>
    )

    return (
        <Section
            className={styles.table}
            reducedSpacingBottom={reducedSpacingBottom}
            noSpacing={noSpacing}
            overflowInitial={true}
            theme={theme}
        >
            <Container>
                <div className={styles.table__containerBg}>
                    <Row>
                        <div className={styles.table__wrapper}>
                            {title && <h4>{title}</h4>}
                            {tableData?.length ? (
                                <div
                                    className={
                                        styles.table__wrapper__tableOuter
                                    }
                                >
                                    <div
                                        className={styles.table__wrapper__table}
                                        ref={tableWrapper}
                                    >
                                        {table}
                                    </div>
                                </div>
                            ) : null}
                            {afterContent && (
                                <div className={styles.table__contentAfter}>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: afterContent,
                                        }}
                                    />
                                </div>
                            )}
                            {!!hubspotForm && (
                                <div className={styles.table__hubspotForm}>
                                    <Form
                                        portalId={hubspotForm.portalId}
                                        formId={hubspotForm.formId}
                                    />
                                </div>
                            )}
                        </div>
                    </Row>
                </div>
            </Container>
        </Section>
    )
}

export default Table
