import React from "react"
import styles from "./Toggle.module.scss"

const Toggle = ({
    mobileText,
    leftText,
    rightText,
    isLeft,
    className,
    onClick,
}) => {
    return (
        <button className={`${styles.toggle} ${className}`} onClick={onClick}>
            <span className={styles.toggle__mobileText}>{mobileText}</span>
            <span className={styles.toggle__desktopText}>{leftText}</span>{" "}
            <span className={styles.toggle__indicator}>
                <span
                    className={`${styles.toggle__indicator__dot} ${
                        !isLeft ? styles.toggle__indicator__dotRight : ""
                    }`}
                ></span>
            </span>{" "}
            <span className={styles.toggle__desktopText}>{rightText}</span>
        </button>
    )
}

export default Toggle
