import React from "react"
import styles from "./BackgroundSolid.module.scss"
import getColors from "../../../util/getColors"

const BackgroundSolid = ({
    halfHeight,
    top,
    autoZIndex,
    theme,
    productColor,
}) => {
    const themeColors = getColors(theme, productColor)

    return (
        <div
            className={`${styles.container}
            ${halfHeight ? styles.containerHalfHeight : ""}
            ${autoZIndex ? styles.containerAutoZIndex : ""}
            ${top ? styles.containerTop : ""}`}
            style={themeColors}
        ></div>
    )
}

export default BackgroundSolid
