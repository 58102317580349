import React from "react";
import styles from "./Introduction.module.scss";
import BackgroundImage from "../elements/BackgroundImage";
import FadeUp from "../effects/FadeUp";
import Section from "../layout/Section";
import Row from "../layout/Row";
import Container from "../layout/Container";

const Introduction = ({title, content, image, colorsAlt, shortPaddingBottom, largeSpacing, theme, productColor}) => {
    return title || content ? (
        <Section className={`${styles.introduction} ${shortPaddingBottom ? styles.introductionShortPaddingBottom: ""} ${largeSpacing ? styles.introductionLargeSpacing: ""}`} theme={theme} productColor={productColor}>
            {image && image.length ? <BackgroundImage image={image[0]} /> : null}

            <Container>
                <Row>
                    <FadeUp className={styles.introduction__content}>
                        {title ? <h6 className={colorsAlt ? styles.colorsAlt : ""}>{title}</h6> : null}
                        {content ? <span dangerouslySetInnerHTML={{__html: content}} /> : null}
                    </FadeUp>
                </Row>
            </Container>
        </Section>
    ) : null;
};

export default Introduction;