import React, {useState, useEffect, useRef, useCallback} from "react";
import styles from "./Slider.module.scss";
import FontFaceObserver from "fontfaceobserver";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Container from "../layout/Container";

const Slider = ({items, itemElements, type}) => {

    const [activeIndex, setActiveIndex] = useState(0);
    const [sliderPos, setSliderPos] = useState(0);
    const [sliderHeight, setSliderHeight] = useState(0);
    const [sliderDisableTransition, setSliderDisableTransition] = useState(false);

    const mouseDown = useRef(false);
    const mouseStart = useRef(null);
    const index = useRef(0);
    const slideWidth = useRef(0);
    const numSlides = useRef(items ? items.length : 0);
    const sliderTempPos = useRef(0);
    const mouse = useRef(0);
    const interval = useRef(null);

    const slider = useRef(null);
    const sliderContainer = useRef(null);
    const slides = useRef(null);

    const goToNextSlide = useCallback(() => {
        const moveToIndex = index.current + 1 > numSlides.current - 1 ? 0 : index.current + 1;
        const moveToPos = parseInt(-1 * moveToIndex * slideWidth.current);
        setSliderPos(moveToPos);
        index.current = moveToIndex;
        setActiveIndex(index.current);
    },[]);

    const resetInterval = useCallback(() => {
        clearInterval(interval.current);

        interval.current = setInterval(() => {
            goToNextSlide();
        }, 5000);
    },[goToNextSlide]);

    const handleClick = (e) => {
        const {currentTarget} = e;
        const {index: moveToIndexNaN} = currentTarget.dataset;

        const moveToIndex = parseInt(moveToIndexNaN);

        const moveToPos = parseInt(-1 * moveToIndex * slideWidth.current);
        setSliderPos(moveToPos);
        index.current = moveToIndex;
        setActiveIndex(index.current);

        resetInterval();
    };

    const handleMouseDown = (e) => {
        const {clientX: x, y, touches, button, which} = e;

        const touchX = touches ? touches[0].clientX : null;
        const touchY = touches ? touches[0].clientY : null;

        if(button === 0 || which === 1 || which === 2 || touchX){
            // if button is left click, which == 2 is for left-handed mouse users, or if user touches slider

            mouseDown.current = true;
            mouseStart.current = {
                x: touchX ? touchX : x,
                y: touchY ? touchY : y
            };

            // disable transition during move with mouse
            setSliderDisableTransition(true);
        };

        clearInterval(interval.current);
    };

    const handleMouseMove = useCallback((e) => {
        if(mouseDown.current){
            const {clientX: x, y, touches} = e;

            const touchX = touches ? touches[0].clientX : null;
            const touchY = touches ? touches[0].clientY : null;

            mouse.current = touchX ? touchX : x;
            const mouseY = touchY ? touchY : y;

            const mouseMovedX = mouse.current - mouseStart.current.x;
            const mouseMovedY = mouseY - mouseStart.current.y

            sliderTempPos.current = -(index.current * slideWidth.current) + mouseMovedX;
            setSliderPos(sliderTempPos.current);

            if(touchX && Math.abs(mouseMovedX/mouseMovedY) > 0.6){
                // disable vertical scroll if swipe is more horizontal than vertical
                e.preventDefault();
                disableBodyScroll(slider.current);
            }
        };
    },[]);

    const handleMouseUp = useCallback(() => {
        const mouseWasDown = mouseDown.current;
        mouseDown.current = false;
        if(mouseWasDown){

            // add transition back in
            setSliderDisableTransition(false);

            const mouseMoved = mouse.current - mouseStart.current.x;

            if(Math.abs(mouseMoved) > 100){
                // which way has the user moved the slider
                const direction = Math.sign(mouseMoved);
                // add that onto the index of current slide shown
                index.current += -direction;
                // ensure that index is within 0 to numSlides-1
                index.current = Math.min(numSlides.current-1, Math.max(0, index.current));
                setActiveIndex(index.current);
            };

            // translate slider to position of index
            setSliderPos(index.current * -slideWidth.current);

            // enable vertical scroll
            enableBodyScroll(slider.current);

            // reset interval
            resetInterval();
        };
    },[resetInterval]);

    const checkSliderDimensions = useCallback(() => {
        if(sliderContainer.current){
            const height = sliderContainer.current.scrollHeight;
            if(height !== 0) setSliderHeight(height);
        };

        if(slides.current){
            slideWidth.current = slides.current.getBoundingClientRect().width;
        };
    }, []);

    const onLoad = useCallback(() => {
        checkSliderDimensions();
    }, [checkSliderDimensions]);

    const resize = useCallback(() => {
        checkSliderDimensions();
        
        // update current slider position
        setSliderPos(index.current * -slideWidth.current);
	}, [checkSliderDimensions, setSliderPos]);

    useEffect(() => {
        onLoad();

        const fonts = [
            {
                family: "Spezia",
                data: {
                    weight: 400,
                }
            },
            {
                family: "Spezia",
                data: {
                    weight: 500,
                }
            },
            {
                family: "Spezia",
                data: {
                    weight: 600,
                }
            }
        ];

        const fontObserverPromises = fonts.map(font => {
            const observer = new FontFaceObserver(font.family, font.data);
            return observer.load();
        });

        Promise.all(fontObserverPromises).then(() => {
            onLoad();
        });

        interval.current = setInterval(() => {
            goToNextSlide();
        }, 5000);

        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("touchmove", handleMouseMove, {passive: false});
        window.addEventListener("mouseup", handleMouseUp);
        window.addEventListener("touchend", handleMouseUp);
        window.addEventListener("resize", resize);

        return () => {
            clearInterval(interval.current);

            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("touchmove", handleMouseMove, {passive: false});
            window.removeEventListener("mouseup", handleMouseUp);
            window.removeEventListener("touchend", handleMouseUp);
            window.removeEventListener("resize", resize);
        }
    },[onLoad, resize, handleMouseMove, handleMouseUp, goToNextSlide]);

    const slidesMarkup = itemElements && itemElements.length ? itemElements.map((element,i) => {
        return (
            <div
                className={`${styles.quoteSliderDraggable__sliderContainer__slidesContainer__slide} ${styles.quoteId} ${(i === 0 ? styles.active : "")}`}
                key={i}
                data-index={i}
                ref={slides}
            >
                <Container className={styles.quoteSliderDraggable__sliderContainer__slidesContainer__slide__inner}>
                    {element}
                </Container>
            </div>
        );
    }) : null;

    const controlsMarkup = items && items.length ? items.map((item,i) => {
        const markup = (
            <li className={styles.quoteSliderDraggable__controlsContainer__control} key={i}>
                <button className={`${styles.articleControlIndex} ${(i === activeIndex ? styles.active : "")}`} onClick={handleClick} data-index={i}>
                    <span className={styles.circle}></span>
                </button>
            </li>
        );

        return markup;
    }) : null;

    return items && items.length ? (
        <div className={`${styles.quoteSliderDraggable} ${styles.quoteSliderDraggableTestimonials}`} ref={slider}>
                <div className={styles.quoteSliderDraggable__sliderContainer} style={{height: `${sliderHeight}px`}}>
                    <button
                        className={`${styles.quoteSliderDraggable__sliderContainer__slidesContainer} ${(sliderDisableTransition? styles.disableTransition : "")} ${type === "testimonials" ? styles.quoteSliderDraggable__sliderContainer__slidesContainerTestimonial : styles.quoteSliderDraggable__sliderContainer__slidesContainerSolution}`}
                        onMouseDown={handleMouseDown}
                        onTouchStart={handleMouseDown}
                        ref={sliderContainer}
                        style={{transform: `translateX(${sliderPos}px)`}}
                    >
                        {slidesMarkup}
                    </button>
                </div>
                <ul className={styles.quoteSliderDraggable__controlsContainer}>
                    {controlsMarkup}
                </ul>
            
        </div>
    ) : null;
};

export default Slider;