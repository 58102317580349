import React from "react";

const Tick = () => {
    return (
        <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg" className="tick-icon">
            <path d="M3.0861 5.82607L1.18493 4.01042L0 5.15615L3.08871 8.02083L9.47917 1.14585L8.28333 0L3.0861 5.82607Z" />
        </svg>
    );
};

export default Tick;