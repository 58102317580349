import React from "react"
import Hero from "../Hero/Hero"

const HeroPricing = ({
    pageTitle,
    title,
    description,
    tabs,
    activeTab,
    theme,
    updatePageState,
}) => {
    return (
        <Hero
            pageTitle={pageTitle}
            title={title}
            description={description}
            tabs={tabs}
            activeTab={activeTab}
            type="pricing"
            theme={theme}
            updatePageState={updatePageState}
        />
    )
}

export default HeroPricing
