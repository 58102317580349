import React from "react"

const ArrowDownThin = () => {
    return (
        <svg
            width="33"
            height="34"
            viewBox="0 0 33 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="arrow-down-thin-icon"
        >
            <path
                d="M16.5 1.06247L16.5 32.9375"
                strokeWidth="2.125"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.62497 18.0625L16.5 32.9375L31.375 18.0625"
                strokeWidth="2.125"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default ArrowDownThin
